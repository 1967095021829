import React from "react";
import '../common/LeaderBoardSection.css';
import imageFooter from "../../../src/assets/images/misc/footer.png";
import './Footer.css';
const Footer = (props) => {
  return <div className='footer' >
               <img src={imageFooter} />
                
        </div>;
};

export default Footer;