import React, { useEffect, useState } from 'react';
import logoPlaceholder from '../../assets/images/icons/iconplaceholder.png';
import eltonBg from '../../assets/images/backgrounds/elton_bg.png';
import wizzardBg from '../../assets/images/backgrounds/wizzard_bg.png';
import hobbitBg from '../../assets/images/backgrounds/hobbit_bg.png';
import pirateBg from '../../assets/images/backgrounds/pirate_bg.png';
import gunsrosesBg from '../../assets/images/backgrounds/gunsroses_bg.png';
import godfatherBg from '../../assets/images/backgrounds/godfather_bg.png';
import willyBg from '../../assets/images/backgrounds/willy_bg.png';
import toystoryBg from '../../assets/images/backgrounds/toystory_bg.png';
import dialedinBg from '../../assets/images/backgrounds/dialedin_bg.png';
import eltonIcon from '../../assets/images/icons/elton.png';
import wizzardIcon from '../../assets/images/icons/wizzard.png';
import godFatherIcon from '../../assets/images/icons/godfather.png';
import toyStoryIcon from '../../assets/images/icons/toystory.png';
import gunsrosesIcon from '../../assets/images/icons/gunsroses.png';
import willyIcon from '../../assets/images/icons/willy.png';
import pirateIcon from '../../assets/images/icons/pirate.png';
import dialedinIcon from '../../assets/images/icons/dialedin.png';
import hobbitIcon from '../../assets/images/icons/hobbit.png';
import jerseyJack from '../../assets/images/icons/jersey-jack.png';
import '../common/Head.css';
const Head = (props) => {

    const [iconPath, setIconPath] = useState(logoPlaceholder);
    const [background, setBackground] = useState(eltonBg);

    useEffect(() => {
       
        if(props.pinball  == 104) {
            setIconPath(eltonIcon);
            setBackground(eltonBg);
        }
        if(props.pinball  == 39) {
            setIconPath(wizzardIcon);
            setBackground( wizzardBg);
        }
        if(props.pinball  == 12) {
            setIconPath(gunsrosesIcon);
            setBackground(gunsrosesBg);
        }
        if(props.pinball  == 26) {
          setIconPath(hobbitIcon);
            setBackground(hobbitBg);
        }
        if(props.pinball  == 42) {
            setIconPath(dialedinIcon);
            setBackground(dialedinBg);
        }
        if(props.pinball  == 31) {
            setIconPath(willyIcon);
            setBackground(willyBg);
        }
        if(props.pinball  == 24) {
            setIconPath(pirateIcon);
            setBackground(pirateBg);
        }
        if(props.pinball  == 43) {
            setIconPath(godFatherIcon);
            setBackground(godfatherBg);
        }
        if(props.pinball  == 38) {
            setIconPath(toyStoryIcon);
            setBackground(toystoryBg);
        }


    }, [props.pinball]);

    if(!iconPath) { return null; }
 
    var headerStyles = {
        backgroundImage: `linear-gradient(rgba(0,0,0,.2), rgba(0,0,0,.2)),url(${background})`,
        backgroundSize  : 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        width: '2160px',
        height: '760px',
        borderRadius: '0px 0px 64px 64px',
        display: 'flex',
        justifyContent: 'center',
        filter: 'drop-shadow(20px 20px 10px rgba(0,0,0,.25))'
    };

    var containerStyles = {
        position: 'relative',  // Add this to contain absolutely positioned children
        width: '2160px',       // Match the width of the header
    };

    var siderStyles = {
        position: 'absolute',
        right: '14px',
        top: '0',              // Adjust this value to position the image vertically
        zIndex: 1              // Ensure it's above the header image if they overlap
    };

    return (
        <div style={containerStyles}>
            <div className='header-image' style={headerStyles}>
                <div className='head-wrap'>
                    <img src={iconPath} alt="Icon" />
                </div>
            </div>
            <img className='sider' src={jerseyJack} alt="Jersey Jack" style={siderStyles} />
        </div>
    );
};

export default Head;
