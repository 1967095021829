import logo from './logo.svg';
import './App.css';
import Leaderboard from './pages/Leaderboard';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/styles/styles.css';
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Leaderboard />
      </header>
    </div>
  );
}

export default App;
