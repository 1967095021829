import React, { useEffect, useState } from "react";
import moment from "moment";
import '../common/LeaderBoardSection.css';
import LeaderSection from "./LeaderSection";
import LeaderBoardTable from "./LeaderBoardTable";
import Footer from "./Footer";

const LeaderBoardSection = ({ pinball }) => {
  const [players, setPlayers] = useState(null);
  const [leaderPlayer, setLeaderPlayer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const today = moment().format('YYYY-MM-DD');
      try {
        setLoading(true);
        const response = await fetch(`https://api.takohadev.com/api/v1/leaderboards/pinball?gameId=${pinball}&from=${today}&to=${today}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        if (result.players && result.players.length > 0) {
          setPlayers(result.players);
          setLeaderPlayer(result.players[0]);
        } else {
          setPlayers([]);
          setLeaderPlayer(null);
          console.log('No players found');
        }
        setError(null);
      } catch (err) {
        setError(err.message);
        setPlayers([]);
        setLeaderPlayer(null);
      } finally {
        setLoading(false);
      }
    };

    if (pinball) {
      fetchData();
    }
  }, [pinball]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!players || players.length === 0) return <div>No players found</div>;
  if (!leaderPlayer) return <div>No leader found</div>;

  return (
    <div className='leaderboard-section'>
      <LeaderSection leader={leaderPlayer} />
      <LeaderBoardTable players={players} />
      <Footer />
    </div>
  );
};

export default LeaderBoardSection;