import React, { useState, useEffect } from 'react';
import moment from 'moment';
import Head from '../components/common/Head';
import LeaderSection from '../components/common/LeaderSection';
import LeaderBoardSection from '../components/common/LeaderBoardSection';

function Leaderboard() {
  const [pinballs, setPinBalls] = useState([]);
  const [pinball, setPinball] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch('https://api.takohadev.com/api/v1/leaderboards/pinball/games');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        if (result.machines.length > 0) {
          setPinBalls(result.machines);
          // Set the first pinball immediately
          setPinball(result.machines[0].id);
        } else {
          alert('no machines found');
        }
        setError(null);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Initial fetch

    // We'll set up the interval for changing pinballs in a separate useEffect
  }, []); // Empty dependency array, so this only runs once on mount

  useEffect(() => {
    if (pinballs.length === 0) return; // Don't do anything if we don't have pinballs yet

    const changePinball = () => {
      setCurrentIndex((prevIndex) => {
        const newIndex = (prevIndex + 1) % pinballs.length;
        setPinball(pinballs[newIndex].id);
        return newIndex;
      });
    };

    // Initial change
    changePinball();

    const changeInterval = setInterval(changePinball, 100000);

    // Cleanup function
    return () => {
      clearInterval(changeInterval);
    };
  }, [pinballs]); // This effect depends on pinballs

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <>
      {pinball && <Head pinball={pinball} />}
      <LeaderBoardSection pinball={pinball}/>
    </>
  );
}

export default Leaderboard;