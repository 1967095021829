import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../common/LeaderBoardTable.css';
import LeaderBoardTableRow from './LeaderBoardTableRow';

const LeaderBoardTable = (props) => {
    let users = props.players;
  return <div className='leaderboard-table-section'>
            
            <table className='leaderboard-table-table'>
                <tbody>
                {users.map((rank,idx) => (  
                    <LeaderBoardTableRow rank={rank} place={idx} />
                ))}  
                </tbody>
            </table>
        </div>;
};

export default LeaderBoardTable;
