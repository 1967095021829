import React, { useEffect } from "react";
import '../common/LeaderSection.css';
import grandchampion from '../../assets/images/grandchampion.png';
import iconUp from '../../assets/images/misc/icon-up.png';

const LeaderSection = (props) => {
    let user = props.leader;
    let iconClass = 'icon-up leader';
    let icon = ' fa-solid fa-circle-arrow-up';
    
     useEffect(() => {
        
            if (user.currentHighestScore > user.previousHighestScore){
                iconClass = 'icon-up leader';
                icon = ' fa-solid fa-circle-arrow-up';
                
            }else if (user.currentHighestScore < user.previousHighestScore){
                iconClass = 'icon-down leader';
                icon = ' fa-solid fa-circle-arrow-down';
                
            }else{
                iconClass = 'icon-retain leader';
                icon = ' fa-solid fa-minus-circle';
            }
        }
    , [user]);
    
    

  return <div className='leader-section' >
    <div>
        <div><h6>&nbsp;</h6></div>
        <div className="label-section">
            <img src={iconUp} />
        </div>
        <div className="label-section">
        </div>
    </div>
    <div>  
        <div className="grand-section">
            <img src={grandchampion}/>
        </div>
        <div className="label-section">
            <h2>{user.username}</h2>
        </div>
        <div className="label-section-points">
            <span>{user.currentHighestScore.toLocaleString()}</span>
        </div>
    </div>
          
    </div>;
};

export default LeaderSection;