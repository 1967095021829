import React from "react";
import '../common/LeaderBoardTableRow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconUp from '../../assets/images/misc/icon_up_sm.png';
import iconDown from '../../assets/images/misc/icondown_sm.png';
import iconNeutral from '../../assets/images/misc/neutral_sm.png';

const LeaderBoardTableRow = (props) => {
    let user = props.rank;
    let place = props.place
    let iconClass = 'icon-up leader';
    let icon = ' fa-solid fa-circle-arrow-up';
    let rankstyle = {
        backgroundImage: 'linear-gradient(to bottom right, #BC841F, #F9C66C)'
    };
    
    switch (place) {
        case 0:
            rankstyle = {
                backgroundImage: 'linear-gradient(to bottom right, #BC841F, #F9C66C)'
            };
            break;
        case 1:
            rankstyle = {
                backgroundImage: 'linear-gradient(to bottom right, #A0A5C5, #CFE0E2)'
            };
            break;
    
        case 2:
            rankstyle = {
                backgroundImage: 'linear-gradient(to bottom right, #874A12, #D3975F)'
            };
            break;
            
            default:
                rankstyle = {
                backgroundImage: 'linear-gradient(to bottom right, rgb(131 131 131), rgb(131 131 131))',
                color: 'black'
            };
            break;
    }
        if (user.currentHighestScore > user.previousHighestScore){
            iconClass = 'icon-up';
            icon = iconUp;
        }else if (user.currentHighestScore < user.previousHighestScore){
            iconClass = 'icon-down';
            icon = iconDown;
        }else{
            iconClass = 'icon-retain';
            icon = iconNeutral;
        }

  return <>
               <tr>
                    <td><div className="row-ranks" style={rankstyle}>{place+1}</div></td>
                    <td>        
                        <img src={icon}></img>
                    </td>
                    <td>{user.username}</td>
                    <td className="row-points">{user.currentHighestScore.toLocaleString()}</td>
                </tr>
        </>;
};

export default LeaderBoardTableRow;